<template>
  <div>
    <div class="table-responsive">
      <table width="100%" cellspacing="0" class="table table-bordered mb-0">
        <thead>
          <tr>
            <th style="width: 15%">{{ $t("facility.navbar.sales") }}</th>
            <th class="text-center" v-for="month in months" :key="month.number">
              {{ `${filter.year}/${month.number}` }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("facility.sales.num-of-booking-sold") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template v-if="typy(data.sales, `${month.number}`).isDefined">
                {{ data.sales[`${month.number}`][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-cancellation") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template
                v-if="typy(data.cancelled, `${month.number}`).isDefined"
              >
                {{ data.cancelled[`${month.number}`][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-net") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template v-if="typy(data.sales, `${month.number}`).isDefined">
                {{
                  typy(data.cancelled, `${month.number}`).isUndefined
                    ? data.sales[`${month.number}`][0].count
                    : data.sales[`${month.number}`][0].count -
                      data.cancelled[`${month.number}`][0].count
                }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.gross-sales") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template v-if="typy(data.sales, `${month.number}`).isDefined">
                {{ data.sales[`${month.number}`][0].amount }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.cancellation-sales") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template
                v-if="typy(data.cancelled, `${month.number}`).isDefined"
              >
                {{ data.cancelled[`${month.number}`][0].amount }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.net-sales") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template v-if="typy(data.sales, `${month.number}`).isDefined">
                {{
                  typy(data.cancelled, `${month.number}`).isUndefined
                    ? data.sales[`${month.number}`][0].amount
                    : data.sales[`${month.number}`][0].amount -
                      data.cancelled[`${month.number}`][0].amount
                }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive">
      <table width="100%" cellspacing="0" class="table table-bordered mb-0">
        <thead>
          <tr>
            <th style="width: 15%">{{ $t("facility.sales.bookings") }}</th>
            <th class="text-center" v-for="month in months" :key="month.number">
              {{ `${filter.year}/${month.number}` }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("facility.sales.num-of-gross-booking") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template v-if="typy(data.booked, `${month.number}`).isDefined">
                {{ data.booked[`${month.number}`][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-cancellation-requested") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template
                v-if="typy(data.cancelled, `${month.number}`).isDefined"
              >
                {{ data.cancelled[`${month.number}`][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.cancellation-rate") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template
                v-if="
                  typy(data.booked, `${month.number}`).isDefined &&
                    typy(data.cancelled, `${month.number}`).isDefined
                "
              >
                {{
                  (
                    (data.cancelled[`${month.number}`][0].count /
                      data.booked[`${month.number}`][0].count) *
                    100
                  ).toFixed(2)
                }}%
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-net-bookings") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template v-if="typy(data.booked, `${month.number}`).isDefined">
                {{
                  typy(data.cancelled, `${month.number}`).isUndefined
                    ? data.booked[`${month.number}`][0].count
                    : data.booked[`${month.number}`][0].count -
                      data.cancelled[`${month.number}`][0].count
                }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.gross-sales") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template v-if="typy(data.booked, `${month.number}`).isDefined">
                {{ data.booked[`${month.number}`][0].amount }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.cancellation-sales") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template
                v-if="typy(data.cancelled, `${month.number}`).isDefined"
              >
                {{ data.cancelled[`${month.number}`][0].amount }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.net-sales") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template v-if="typy(data.booked, `${month.number}`).isDefined">
                {{
                  typy(data.cancelled, `${month.number}`).isUndefined
                    ? data.booked[`${month.number}`][0].amount
                    : data.booked[`${month.number}`][0].amount -
                      data.cancelled[`${month.number}`][0].amount
                }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive">
      <table width="100%" cellspacing="0" class="table table-bordered mb-0">
        <thead>
          <tr>
            <th style="width: 15%">{{ $t("facility.sales.occupation") }}</th>
            <th class="text-center" v-for="month in months" :key="month.number">
              {{ `${filter.year}/${month.number}` }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("facility.sales.num-of-gross-inventory") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template
                v-if="typy(data.inventory, `${month.number}`).isDefined"
              >
                {{ data.inventory[`${month.number}`][0].capacity }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-bookings") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template v-if="typy(data.booked, `${month.number}`).isDefined">
                {{ data.booked[`${month.number}`][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.occupancy-rate") }}</td>
            <td class="text-center" v-for="month in months" :key="month.number">
              <template
                v-if="
                  typy(data.booked, `${month.number}`).isDefined &&
                    typy(data.inventory, `${month.number}`).isDefined
                "
              >
                {{
                  (
                    (data.booked[`${month.number}`][0].count /
                      data.inventory[`${month.number}`][0].capacity) *
                    100
                  ).toFixed(2)
                }}%
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import t from "typy";
import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
    filter: {
      type: Object,
      default: {},
    },
    months: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      typy: t,
      month_no: moment().daysInMonth(),
    };
  },
};
</script>

<style lang="scss"></style>
