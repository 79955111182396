<template>
  <div>
    <div class="table-responsive">
      <table width="100%" cellspacing="0" class="table table-bordered mb-0">
        <thead>
          <tr>
            <th style="width: 15%">{{ $t("facility.navbar.sales") }}</th>
            <th
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              {{ `${$t("facility.sales.week")} ${week}` }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("facility.sales.num-of-booking-sold") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.sales, week).isDefined">
                {{ data.sales[week][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-cancellation") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.cancelled, week).isDefined">
                {{ data.cancelled[week][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-net") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template
                v-if="
                  typy(data.sales, week).isDefined &&
                    typy(data.cancelled, week).isDefined
                "
              >
                {{ data.sales[week][0].count - data.cancelled[week][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.gross-sales") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.sales, week).isDefined">
                {{ data.sales[week][0].amount }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.cancellation-sales") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.cancelled, week).isDefined">
                {{ data.cancelled[week][0].amount }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.net-sales") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template
                v-if="
                  typy(data.sales, week).isDefined &&
                    typy(data.cancelled, week).isDefined
                "
              >
                {{
                  data.sales[week][0].amount - data.cancelled[week][0].amount
                }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive">
      <table width="100%" cellspacing="0" class="table table-bordered mb-0">
        <thead>
          <tr>
            <th style="width: 15%">{{ $t("facility.sales.bookings") }}</th>
            <th
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              {{ `${$t("facility.sales.week")} ${week}` }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("facility.sales.num-of-gross-booking") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.booked, week).isDefined">
                {{ data.booked[week][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-cancellation-requested") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.cancelled, week).isDefined">
                {{ data.cancelled[week][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.cancellation-rate") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template
                v-if="
                  typy(data.booked, week).isDefined &&
                    typy(data.cancelled, week).isDefined
                "
              >
                {{
                  (
                    (data.cancelled[week][0].count /
                      data.booked[week][0].count) *
                    100
                  ).toFixed(2)
                }}%
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-net-bookings") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.booked, week).isDefined">
                {{
                  typy(data.cancelled, week).isUndefined
                    ? data.booked[week][0].count
                    : data.booked[week][0].count - data.cancelled[week][0].count
                }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.gross-sales") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.booked, week).isDefined">
                {{ data.booked[week][0].amount }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.cancellation-sales") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.cancelled, week).isDefined">
                {{ data.cancelled[week][0].amount }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.net-sales") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.booked, week).isDefined">
                {{
                  typy(data.cancelled, week).isUndefined
                    ? data.booked[week][0].amount
                    : data.booked[week][0].amount -
                      data.cancelled[week][0].amount
                }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive">
      <table width="100%" cellspacing="0" class="table table-bordered mb-0">
        <thead>
          <tr>
            <th style="width: 15%">{{ $t("facility.sales.occupation") }}</th>
            <th
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              {{ `${$t("facility.sales.week")} ${week}` }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("facility.sales.num-of-gross-inventory") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.inventory, week).isDefined">
                {{ data.inventory[week][0].capacity }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.num-of-bookings") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template v-if="typy(data.booked, week).isDefined">
                {{ data.booked[week][0].count }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("facility.sales.occupancy-rate") }}</td>
            <td
              class="text-center"
              v-for="week in data.weeksOfMonth"
              :key="week"
            >
              <template
                v-if="
                  typy(data.booked, week).isDefined &&
                    typy(data.inventory, week).isDefined
                "
              >
                {{
                  (
                    (data.booked[week][0].count /
                      data.inventory[week][0].capacity) *
                    100
                  ).toFixed(2)
                }}%
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import t from "typy";
import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
    filter: {
      type: Object,
      default: {},
    },
    months: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      typy: t,
      month_no: moment().daysInMonth(),
    };
  },
};
</script>

<style lang="scss"></style>
