import TableDayComponent from "./table-data/day.component.vue";
import TableMonthComponent from "./table-data/month.component.vue";
import TableWeekComponent from "./table-data/week.component.vue";
import {facilitySalesService} from "@/services/facility/sales/sales.service";
import {alertService} from "@/services/alert/alert.service";
import { months, years } from "@/utils/mockup";
import moment from "moment";
export default {
    components: {
        TableDayComponent,
        TableMonthComponent,
        TableWeekComponent
    },
    data() {
        return {
            parkings: [],
            parkingsReady: false,
            data: {},
            dataReady: false,
            filter: {
                paginate : 0,
                per_page: 10,
                parking_id: 0,
                periodical: 1,
                month: moment().month() + 1,
                year: moment().year(),
            },
            months,
            years,
        }
    },
    async mounted() {
        this.parkingList();
    },
    methods: {
        async parkingList() {
            this.parkingsReady = false
            alertService.loading();
            await facilitySalesService.parkingList().then((response) => {
                this.parkings = response.data.data
                this.parkingsReady = true
                if(this.parkings.length > 0) {
                    this.filter.parking_id = this.parkings[0].id
                    this.sales()
                } else {
                    alertService.close();
                }
            }).catch((error) => {
                alertService.close();
                console.log(error)
            });
        },

        async sales() {
            this.dataReady = false
            await facilitySalesService.list(this.filter).then((response) => {
                this.data = response.data
                this.dataReady = true
                alertService.close();
            }).catch((error) => {
                alertService.close();
                console.log(error)
            });
        },

        changeParking(id) {
            this.filter.parking_id = id
            alertService.loading();
            this.sales()
        },

        changePeriodical(x) {
            this.filter.periodical = x
            alertService.loading();
            this.sales()
        },

        changeDate() {
            alertService.loading();
            this.sales()
        }
    }

}