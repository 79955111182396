import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilitySalesService {
    async list(data) {
        promise = await api.client.get(config.end_point.facility.sales, {params: data});
        return promise;
    }

    async parkingList() {
        promise = await api.client.get(config.end_point.facility.parkings +`?paginate=1&per_page=10&airport_id=${config.airport_id}`);
        return promise;
    }
}

export const facilitySalesService = new FacilitySalesService();
